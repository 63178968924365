<template>
  <div>
    <div>
      <div class="d-flex mb-2">
        <nav class="nav nav-pills nav-justified flex-fill">
          <a :class="['gray nav-item nav-link', {'active_custom': type === 'sms'}, {'disabled-type' : auditRecord.sms === null}]" href="javascript:void(0);" @click="setTypeTo('sms', auditRecord.sms === null)">SMS</a>
          <a :class="['gray nav-item nav-link', {'active_custom': type === 'isps'}, {'disabled-type' : auditRecord.isps === null}]" href="javascript:void(0);" @click="setTypeTo('isps', auditRecord.isps === null)">ISPS</a>
          <a :class="['gray nav-item nav-link', {'active_custom': type === 'cyber'}, {'disabled-type' : auditRecord.cyber === null}]" href="javascript:void(0);" @click="setTypeTo('cyber', auditRecord.cyber === null)">Cyber</a>
        </nav>
        <div style="padding: 2px;" v-if="user.id === selectedAuditRecord.created_by_id || (isAllowed('vessel-ia.appointment.edit') && isOwnVessel('vesselAuditRecord'))">
          <div class="float-right ml-2 dropdown dropright">
            <font-awesome-icon
              icon="cog"
              class="cursor-pointer"
              @mouseover="auditTypeSettingColor = 'e-text-red'"
              @mouseout="auditTypeSettingColor = ''"
              :class="auditTypeSettingColor"
              style="font-size: 26px;
              margin: auto"
              data-toggle="dropdown"
              aria-expanded="false"
              aria-haspopup="true"
            />
            <div aria-labelledby="actionAIRDropdown" class="dropdown-menu">
              <div class="d-flex dropdown-item no-bg-change cursor-pointer" @click.stop style="position: relative">
                <div class="form-check form-check-inline">
                  <input :checked= "audit_main_types[0].enabled" class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_sms" v-model="audit_main_types[0].enabled">
                  <label for="audit_type1_sms" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">SMS</label>
                </div>
                <div class = "transparent-audit-type" @click="setAuditTypeOne(0,'sms')">
                </div>
              </div>
              <div class="d-flex dropdown-item no-bg-change cursor-pointer" @click.stop style="position: relative">
                <div class="form-check form-check-inline">
                  <input :checked= "audit_main_types[1].enabled" class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_isps" v-model="audit_main_types[1].enabled">
                  <label for="audit_type1_isps" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">ISPS</label>
                </div>
                <div class = "transparent-audit-type" @click="setAuditTypeOne(1,'isps')">
                </div>
              </div>
              <div class="d-flex dropdown-item no-bg-change cursor-pointer" @click.stop style="position: relative">
                <div class="form-check form-check-inline">
                  <input :checked= "audit_main_types[2].enabled" class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_cyber" v-model="audit_main_types[2].enabled">
                  <label for="audit_type1_cyber" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">CYBER</label>
                </div>
                <div class = "transparent-audit-type" @click="setAuditTypeOne(2,'cyber')">
                </div>
              </div>
              <div class="d-flex dropdown-item no-bg-change cursor-pointer p-1">
                <button class="btn e-btn-blue btn-xs font-weight-bolder w-100 text-white" type="button"
                        :disabled="disabledAuditSetting"
                        @click="saveAuditTypeSetting">
                  <font-awesome-icon icon="save" class="force-white-all">
                  </font-awesome-icon>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



<!--    <template>-->
<!--      <app-loading></app-loading>-->
<!--    </template>-->

    <template v-if="!isEditing">
      <div class="card p-3 shadow-sm">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red">
            INTERNAL AUDIT ({{ type.toUpperCase() }})
          </h5>
        </div>
        <div class="col-auto">
          <button class="float-right px-2 py-1 e-btn-blue-gray force-white-all rounded" type="button" @click="redirectToAuditRecordList"
          >
            <font-awesome-icon icon="chevron-left" class="e-text-black"/> Go Back
          </button>

          <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="startEditing"
                  v-if="user.id === selectedAuditRecord.created_by_id || (isAllowed('vessel-ia.appointment.edit') && isOwnVessel('vesselAuditRecord'))"
          >
            Edit Report
          </button>

          <div class="float-right ml-2 dropdown dropright">
            <button id="downloadIaDocuments" aria-expanded="false" aria-haspopup="true" class="mr-2 e-btn e-btn-blue-gray force-white-all"
                    data-toggle="dropdown"
                    v-if="isAllowed('vessel-ia.appointment.download.appointment') ||
                    isAllowed('vessel-ia.appointment.download.attendance') ||
                    isAllowed('vessel-ia.appointment.download.corrective-action') ||
                    isAllowed('vessel-ia.appointment.download.non-conformity') ||
                    isAllowed('vessel-ia.appointment.download.ia-report') ||
                    isAllowed('vessel-ia.appointment.download.all')"
            >
              <font-awesome-icon class="mr-1" icon="download"/>
              Download Document
            </button>
            <div aria-labelledby="actionAIRDropdown" class="dropdown-menu">
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('appointment_plan')"
                      v-if="isAllowed('vessel-ia.appointment.download.appointment')"
              >
                <font-awesome-icon icon="file-pdf"/>
                Audit appointments and Plan
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('attendance')"
                      v-if="isAllowed('vessel-ia.appointment.download.attendance')"
              >
                <font-awesome-icon icon="file-pdf"/>
                Attendance
              </button>
              <button :class="{'disabled-element' : internal_audit_reports[type]?.non_conformity_count === 0 && internal_audit_reports[$route.params.type]?.observation_count === 0}" :disabled="internal_audit_reports[type]?.non_conformity_count === 0 && internal_audit_reports[$route.params.type]?.observation_count === 0" class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('corrective_actions', internal_audit_reports[type]?.non_conformity_count === 0 && internal_audit_reports[$route.params.type]?.observation_count === 0)"
                      v-if="isAllowed('vessel-ia.appointment.download.corrective-action')"
              >
                <font-awesome-icon icon="file-pdf"/>
                Corrective Actions Report
              </button>
              <button :class="{'disabled-element' : internal_audit_reports[type]?.non_conformity_count === 0 && internal_audit_reports[$route.params.type]?.observation_count === 0}" :disabled="internal_audit_reports[type]?.non_conformity_count === 0 && internal_audit_reports[$route.params.type]?.observation_count === 0" class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('non_conformity', internal_audit_reports[type]?.non_conformity_count === 0 && internal_audit_reports[$route.params.type]?.observation_count === 0)"
                      v-if="isAllowed('vessel-ia.appointment.download.non-conformity')"
              >
                <font-awesome-icon icon="file-pdf"/>
                Non-Conformity/Observation Note for {{ type.toUpperCase() }}
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('internal_audit_report')"
                      v-if="isAllowed('vessel-ia.appointment.download.ia-report')"
              >
                <font-awesome-icon icon="file-pdf"/>
                Internal Audit Report ({{ type.toUpperCase() }})
              </button>
              <button :class="{'disabled-element' : internal_audit_reports[type]?.checklist_url === null}" :disabled="internal_audit_reports[type]?.checklist_url === null" class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('internal_audit_report_checklist', internal_audit_reports[type]?.checklist_url === null)"
                      v-if="isAllowed('vessel-ia.appointment.download.checklist')"
              >
                <font-awesome-icon icon="file-pdf"/>
                Checklist for Internal Audit Report ({{ type.toUpperCase() }})
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadDocument('all')"
                      v-if="isAllowed('vessel-ia.appointment.download.all')"
              >
                <font-awesome-icon icon="file-pdf"/>
                All For ({{ type.toUpperCase() }})
              </button>
            </div>
          </div>

        </div>

      </div>

      <h6 class="font-weight-bolder">Appointment and Plan Report</h6>
      <table class="w-100">
        <tr>
          <td style="width: 24%;">Report Number</td>
          <td style="width: 38%;">{{ auditRecord[type]?.report_number }}</td>
          <td style="width: 12%;">
            Status
            <font-awesome-icon
              icon="question-circle"
              data-toggle="modal"
              style="cursor:pointer"
              data-target="#requirementsBeforeClosing">
            </font-awesome-icon>
          </td>
          <td v-if="editTypeStatus" :key="$route.params.type">
            <div class="row align-content-center align-items-center">
              <div class="col-8">
                <select class="form-control form-control-sm" v-model="auditRecord[type].report_status_id">
                  <template v-for="reportStatusKey in reportTypeStatusKeys">
                    <option v-if="reportStatusKey.visible" :value="reportStatusKey.id" :disabled="reportStatusKey.disabled">
                      {{ reportStatusKey.name }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-success btn-xs mr-2" :disabled="auditRecord[type]?.report_status_id === selectedAuditRecord[type]?.report_status_id"
                        @click="saveTypeStatus">
                  <font-awesome-icon icon="save" class="force-white-all">

                  </font-awesome-icon>
                  Save
                </button>
                <button type="button" class="btn btn-danger btn-xs" @click="cancelEditTypeStatus">
                  <font-awesome-icon icon="ban" class="force-white-all"></font-awesome-icon>
                  Cancel
                </button>
              </div>
            </div>
          </td>
          <td v-else>
            <button type="button" class="'e-btn e-btn-xs px-5" :class="['bg-'+convertReportStatus(auditRecord[type]?.report_status_id).color]"
                    @click="editTypeStatusValue"
                    :disabled="editableReportStatusButton === false"
            >{{ convertReportStatus(auditRecord[type]?.report_status_id).name ?? 'NEW' }}
            </button>
          </td>
        </tr>
        <tr>
          <td style="width: 24%;">Vessel <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 38%;">{{ vessel.name? vessel.name : ''}}</td>
          <td style="width: 12%;">
            Date of Audit <sub class="lead e-text-red font-weight-bold">*</sub>
          </td>
          <td>
            {{ auditRecord[type]?.audit_date_human[0] === auditRecord[type]?.audit_date_human[1] ? auditRecord[type]?.audit_date_human[0] : auditRecord[type]?.audit_date_human[0] + ' - ' + auditRecord[type]?.audit_date_human[1]}}
          </td>
        </tr>
        <tr>
          <td style="width: 24%;">Audit Type 2 <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 38%;"><b>{{ convertAuditType2IntoType(auditRecord[type]?.audit_type_2 ?? []) }}</b></td>
          <td style="width: 12%;">
            Appointment Date <sub class="lead e-text-red font-weight-bold">*</sub>
          </td>
          <td>
            {{ auditRecord[type]?.appointment_date_human }}
          </td>
        </tr>
        <tr v-if="auditRecord[type]?.audit_type_2.length === 1 && auditRecord[type]?.audit_type_2[0] === 2">
          <td style="width: 12%;">Reason of this audit <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 50%;">
            <template v-if="auditRecord[type]?.reason_of_audit.file_path === null">
              <span class="e-text-red"> No File Uploaded </span>
            </template>
            <template v-else>
              <div class="dropdown dropright">
                <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                   id="actionDropdown"
                                   aria-expanded="false"
                                   aria-haspopup="true"
                                   data-toggle="dropdown"
                ></font-awesome-icon>
                <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                  <button class="dropdown-item text-secondary font-weight-bolder"
                          @click="viewReasonToAudit"
                  >
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                </div>
              </div>
            </template>
          </td>
        </tr>
        <tr>
          <td style="width: 24%;">Audit Type 3 <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 38%;"><b>{{ convertAuditType3IntoType(auditRecord[type]?.audit_type_3 ?? []) }}</b></td>
          <td style="width: 12%;">
            Extension
          </td>
          <td>
            {{ auditRecord[type]?.extension === 0 ? 'NO' : 'YES'}}
          </td>
        </tr>
        <tr>
          <template v-if="auditRecord[type]?.audit_type_3.length === 1 && auditRecord[type]?.audit_type_3[0] === 2">
            <td style="width: 24%;">Have you received approval from the Flag State?</td>
            <td style="width: 38%;">
              {{ auditRecord[type]?.received_approval === 0 ? 'NO' : 'YES' }}
            </td>
          </template>
          <template v-else>
            <td style="width: 24%;"></td>
            <td style="width: 38%;"></td>
          </template>

          <template v-if="auditRecord[type]?.extension === 1">
            <td style="width: 12%;">
              Extension Date <sub class="lead e-text-red font-weight-bold">*</sub>
            </td>
            <td>
              {{ auditRecord[type]?.extension_date_human }}
            </td>
          </template>
        </tr>

        <tr>
          <template v-if="auditRecord[type]?.audit_type_3.length === 1 && auditRecord[type]?.audit_type_3[0] === 2 && auditRecord[type].received_approval === 1">
            <td style="width: 24%;">Evidence of Approval from Flag State <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td style="width: 38%;">
              <template v-if="auditRecord[type]?.evidence_of_approval.file_path === null">
                <span class="e-text-red"> No File Uploaded </span>
              </template>
              <template v-else>
                <div class="dropdown dropright">
                  <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                     id="actionDropdown"
                                     aria-expanded="false"
                                     aria-haspopup="true"
                                     data-toggle="dropdown"
                  ></font-awesome-icon>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            @click="viewEvidenceOfApproval"
                    >
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                  </div>
                </div>
              </template>
            </td>
          </template>
          <template v-else>
            <td style="width: 24%;"></td>
            <td style="width: 38%;"></td>
          </template>
        </tr>
      </table>
      <hr/>
      <table class="w-100">
        <tr>
          <td style="width: 19%;">Country <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 40%;">{{ filterDataById(countryKeys, auditRecord[type]?.audit_country_id).length === 0 ? '' : filterDataById(countryKeys, auditRecord[type]?.audit_country_id)[0].name }}</td>
          <td style="width: 15%;">
            Port
          </td>
          <td>
            {{ filterDataById(portKeys, auditRecord[type]?.audit_port_id).length === 0 ? '' : filterDataById(portKeys, auditRecord[type]?.audit_port_id)[0].name + ' ' + filterDataById(portKeys, auditRecord[type]?.audit_port_id)[0].locode_wrap }}
          </td>
        </tr>
        <tr>
          <td style="width: 19%;">Management Branch Company</td>
          <td style="width: 40%;">{{ vessel.management_company_name? vessel.management_company_name : ''}}</td>
          <td style="width: 15%;">Management Branch Office</td>
          <td>
            {{ vessel.management_office_name? vessel.management_office_name : ''}}
          </td>
        </tr>
        <tr>
          <td style="width: 19%;">Lead Auditor <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 40%;">
            <multi-select-with-search
              :key="type + '_lead_' + isEditing"
              id="lead-data"
              :items="leadAndNonLeadAuditors" :entity="'Lead'"
              :selected="auditRecord[type].lead_ids"
              :edit-mode="false"
            >
            </multi-select-with-search>
          </td>
          <td style="width: 15%;">Auditors</td>
          <td>
            <multi-select-with-search
              :key="type + '_member_' + isEditing"
              id="members-data"
              :items="leadAndNonLeadAuditors" :entity="'Members'"
              :selected="auditRecord[type].members_ids"
              :edit-mode="false"
            >
            </multi-select-with-search>
          </td>
        </tr>
      </table>
      <table class="w-100">
        <tr>
          <td style="width: 19%;">Master</td>
          <td style="width: 40%;">
            <p class="p-0 m-0" v-for="master in master[type]">{{ master.last_name }}, {{ master.first_name }}
              {{ master.middle_name }}
            </p>
          </td>
          <td style="width: 15%;">Deck Dept Head (C/O)</td>
          <td style="width: 40%;">
            <p class="p-0 m-0" v-for="deck_dept_head in deck_dept_head[type]">{{ deck_dept_head.last_name }}, {{ deck_dept_head.first_name }}
              {{ deck_dept_head.middle_name }}
            </p>
          </td>
        </tr>
        <tr>
          <td style="width: 19%;">Eng Dept Head (C/E)</td>
          <td style="width: 40%;">
            <p class="p-0 m-0" v-for="eng_dept_head in eng_dept_head[type]">{{ eng_dept_head.last_name }}, {{ eng_dept_head.first_name }}
              {{ eng_dept_head.middle_name }}
            </p>
          </td>
          <td style="width: 15%;"></td>
          <td></td>
        </tr>
      </table>
    </div>
    </template>

    <template v-else>
      <div class="card p-3 shadow-sm">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red">
            INTERNAL AUDIT ({{ type.toUpperCase() }})
          </h5>
        </div>
        <div class="col-auto">
            <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancel">Cancel</button>
            <button class="e-btn e-btn-blue ml-2" type="submit" @click="saveChanges" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Save</button>
        </div>
      </div>
      <h6 class="font-weight-bolder">Appointment and Plan Report</h6>
      <table class="w-100">
        <tr>
          <td style="width: 24%;">Report Number</td>
          <td style="width: 38%;">{{ auditRecord[type]?.report_number }}</td>
          <td style="width: 12%;">
            Status
            <font-awesome-icon
              icon="question-circle"
              data-toggle="modal"
              style="cursor:pointer"
              data-target="#requirementsBeforeClosing">
            </font-awesome-icon>
          </td>
          <td v-if="editTypeStatus">
            <div class="row align-content-center align-items-center">
              <div class="col-8">
                <select class="form-control form-control-sm" v-model="auditRecord[type].report_status_id">
                  <template v-for="reportStatusKey in reportTypeStatusKeys">
                    <option v-if="reportStatusKey.visible" :value="reportStatusKey.id" :disabled="reportStatusKey.disabled">
                      {{ reportStatusKey.name }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-success btn-xs mr-2" :disabled="auditRecord[type]?.report_status_id === selectedAuditRecord[type]?.report_status_id"
                        @click="saveTypeStatus">
                  <font-awesome-icon icon="save" class="force-white-all">

                  </font-awesome-icon>
                  Save
                </button>
                <button type="button" class="btn btn-danger btn-xs" @click="cancelEditTypeStatus">
                  <font-awesome-icon icon="ban" class="force-white-all"></font-awesome-icon>
                  Cancel
                </button>
              </div>
            </div>
          </td>
          <td v-else>
            <button type="button" class="'e-btn e-btn-xs px-5" :class="['bg-'+convertReportStatus(auditRecord[type]?.report_status_id).color]"
                    @click="editTypeStatus = true"
                    :disabled="editableReportStatusButton === false"
            >{{ convertReportStatus(auditRecord[type]?.report_status_id).name ?? 'NEW' }}
            </button>
          </td>
        </tr>
        <tr>
          <td style="width: 24%;">Vessel <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 38%;">{{ vessel.name? vessel.name : ''}}</td>
          <td style="width: 12%;">
            Date of Audit <sub class="lead e-text-red font-weight-bold">*</sub>
          </td>
          <td>
            <date-picker v-model="auditRecord[type].audit_date" :lang = 'en' type="date" valueType="format" range :editable="false"></date-picker>
          </td>
        </tr>
        <tr>
          <td style="width: 24%;">Audit Type 2 <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 38%;">
            <div class="form-group row no-gutters mb-1">
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_annual" @click="setAuditTypeTwo(1)" :checked="auditRecord[type]?.audit_type_2.includes(1)">
                <label for="audit_type2_annual" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Annual</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_occasional" @click="setAuditTypeTwo(2)" :checked="auditRecord[type]?.audit_type_2.includes(2)">
                <label for="audit_type2_occasional" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Occasional</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_navigational" @click="setAuditTypeTwo(3)" :checked="auditRecord[type]?.audit_type_2.includes(3)">
                <label for="audit_type2_navigational" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Navigational Audit</label>
              </div>
            </div>
          </td>
          <td style="width: 12%;">
            Appointment Date <sub class="lead e-text-red font-weight-bold">*</sub>
          </td>
          <td>
            <date-picker v-model="auditRecord[type].appointment_date" :lang = 'en' type="date" valueType="format" :editable="false" :clearable="false" style="width: 100%"></date-picker>
          </td>
        </tr>
        <tr v-if="auditRecord[type]?.audit_type_2.length === 1 && auditRecord[type]?.audit_type_2[0] === 2">
          <td style="width: 24%;">Reason of this audit <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 38%;">
            <input type="file" hidden ref="reason_to_audit" accept=".doc,.docx,.pdf" @change="addAuditAttachment('reason_to_audit')"/>
            <button class="e-btn e-btn-green px-3" type="button" @click="$refs.reason_to_audit.click()" v-if="auditRecord[type]?.reason_of_audit.file_path === null && auditRecord[type]?.reason_of_audit.file === null">
              Add Reason of this Audit
            </button>
            <div v-else class="dropdown dropright">
              <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                 id="actionDropdown"
                                 aria-expanded="false"
                                 aria-haspopup="true"
                                 data-toggle="dropdown"
              ></font-awesome-icon>
              <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                <button class="dropdown-item text-secondary font-weight-bolder"
                        v-if="auditRecord[type]?.reason_of_audit.file === null && auditRecord[type]?.reason_of_audit.file_path !== null"
                        @click="viewReasonToAudit"
                >
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
                <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                        @click="deleteReasonToAudit"
                >
                  <font-awesome-icon icon="trash" class="force-danger-all"

                  />
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td style="width: 24%;">Audit Type 3 <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 38%;">
            <div class="form-group row no-gutters mb-1">
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type3_onsite" @click="setAuditTypeThree(1)" :checked="auditRecord[type]?.audit_type_3.includes(1)">
                <label for="audit_type3_onsite" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Onsite</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type3_remote" @click="setAuditTypeThree(2)" :checked="auditRecord[type]?.audit_type_3.includes(2)">
                <label for="audit_type3_remote" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Remote</label>
              </div>
            </div>
          </td>
          <td style="width: 12%;">
            Extension
          </td>
          <td>
            <select-with-search
              :key="type + '_select-search-country_' + auditRecord[type].extension"
              v-model="auditRecord[type].extension"
              :current-selected-item-id="auditRecord[type].extension"
              @change="auditRecord[type].extension_date = null"
              :include-search-input="false"
              :key-item-value="'id'"
              :key-display-text="'name'"
              :items="[
                {
                  id : 0,
                  name : 'NO'
                },
                {
                  id : 1,
                  name : 'YES'
                },
              ]"
              :element-class="'form-control form-control-sm text-uppercase'"
            />

          </td>
        </tr>
        <tr>
          <template v-if="auditRecord[type]?.audit_type_3.length === 1 && auditRecord[type]?.audit_type_3[0] === 2">
            <td style="width: 24%;">Have you received approval from the Flag State?</td>
            <td style="width: 38%;">
              <div style="width: 200px">
                <select-with-search
                  :key="type + '_select-search-country_' + auditRecord[type].received_approval"
                  v-model="auditRecord[type].received_approval"
                  :current-selected-item-id="auditRecord[type].received_approval"
                  :include-search-input="false"
                  :key-item-value="'id'"
                  :key-display-text="'name'"
                  :items="[
                {
                  id : 0,
                  name : 'NO'
                },
                {
                  id : 1,
                  name : 'YES'
                },
              ]"
                  :element-class="'form-control form-control-sm text-uppercase'"
                />
              </div>
            </td>
          </template>
          <template v-else>
            <td style="width: 24%;"></td>
            <td style="width: 38%;"></td>
          </template>

          <template v-if="auditRecord[type]?.extension === 1">
            <td style="width: 12%;">
            <span>
              Extension Date <sub class="lead e-text-red font-weight-bold">*</sub>
            </span>
            </td>
            <td>
              <input class="form-control form-control-sm" type="date" v-if="auditRecord[type]?.extension === 1" style="display: inline; width: fit-content;" v-model="auditRecord[type].extension_date">
            </td>
          </template>

        </tr>
        <tr>
          <template
            v-if="auditRecord[type]?.audit_type_3.length === 1 && auditRecord[type]?.audit_type_3[0] === 2
            && auditRecord[type].received_approval === 1"
          >
            <td style="width: 24%;">Evidence of Approval from Flag State <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td style="width: 38%;">
              <input type="file" hidden ref="evidence_of_approval" accept=".doc,.docx,.pdf" @change="addAuditAttachment('evidence_of_approval')"/>
              <button class="e-btn e-btn-green px-3" type="button" @click="$refs.evidence_of_approval.click()" v-if="auditRecord[type]?.evidence_of_approval.file_path === null && auditRecord[type]?.evidence_of_approval.file === null">
                Add Evidence of Approval
              </button>
              <div v-else class="dropdown dropright">
                <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                   id="actionDropdown"
                                   aria-expanded="false"
                                   aria-haspopup="true"
                                   data-toggle="dropdown"
                ></font-awesome-icon>
                <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                  <button class="dropdown-item text-secondary font-weight-bolder"
                          v-if="auditRecord[type]?.evidence_of_approval.file === null && auditRecord[type]?.evidence_of_approval.file_path !== null"
                          @click="viewEvidenceOfApproval"
                  >
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                  <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                          @click="deleteEvidenceOfApproval"
                  >
                    <font-awesome-icon icon="trash" class="force-danger-all"

                    />
                    Delete
                  </button>
                </div>
              </div>
            </td>
          </template>
          <template v-else>
            <td style="width: 24%;"></td>
            <td style="width: 38%;"></td>
          </template>

        </tr>
      </table>
      <hr/>
      <table class="w-100">
        <tr>
          <td style="width: 19%;">Country <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 40%;">
            <select-with-search
              :key="type + '_select-search-country_' + auditRecord[type].audit_country_id"
              v-model="auditRecord[type].audit_country_id"
              @change="auditRecord[type].audit_port_id = null"
              :current-selected-item-id="auditRecord[type].audit_country_id"
              :search-placeholder="'Search Country...'"
              :key-item-value="'id'"
              :key-display-text="'name'"
              :items="[{
                  id : null,
                  name : '—— ALL COUNTRIES ——'
                }].concat(countryKeys)"
              :element-class="'form-control form-control-sm text-uppercase'"
            />
          </td>
          <td style="width: 15%;">Port</td>
          <td>
            <select-with-search
              :key="type + '_select-search-port_' + auditRecord[type].audit_port_id"
              v-model="auditRecord[type].audit_port_id"
              :current-selected-item-id="auditRecord[type].audit_port_id"
              :search-placeholder="'Search Port...'"
              :disabled="!ports.length"
              :key-item-value="'id'"
              :key-display-text="'name'"
              :items="[{
                  id : null,
                  name : '—— ALL PORTS ——'
                }].concat(ports)"
              :element-class="'form-control form-control-sm text-uppercase'"
            />
          </td>
        </tr>
        <tr>
          <td style="width: 19%;">Management Branch Company</td>
          <td style="width: 40%;">{{ vessel.management_company_name? vessel.management_company_name : ''}}</td>
          <td style="width: 15%;">Management Branch Office</td>
          <td>
            {{ vessel.management_office_name? vessel.management_office_name : ''}}
          </td>
        </tr>
        <tr>
          <td style="width: 19%;">Lead Auditor <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td style="width: 40%;">
            <multi-select-with-search
              :key="type + '_lead_' + isEditing"
              id="lead-data"
              :items="leadAndNonLeadAuditors" :entity="'Lead'"
              @doneSelected="setLeadMembers"
              :selected="auditRecord[type].lead_ids"
            >
            </multi-select-with-search>
          </td>
          <td style="width: 15%;">Auditors</td>
          <td>
            <multi-select-with-search
              :key="type + '_member_' + isEditing"
              id="members-data"
              :items="leadAndNonLeadAuditors" :entity="'Members'"
              @doneSelected="setNonLeadMembers"
              :selected="auditRecord[type].members_ids"
            >
            </multi-select-with-search>
          </td>
        </tr>
      </table>
      <table class="w-100">
        <tr>
          <td style="width: 19%;">Master</td>
          <td style="width: 40%;">
            <p class="p-0 m-0" v-for="master in master[type]">{{ master.last_name }}, {{ master.first_name }}
              {{ master.middle_name }}
            </p>
          </td>
          <td style="width: 15%;">Deck Dept Head (C/O)</td>
          <td style="width: 40%;">
            <p class="p-0 m-0" v-for="deck_dept_head in deck_dept_head[type]">{{ deck_dept_head.last_name }}, {{ deck_dept_head.first_name }}
              {{ deck_dept_head.middle_name }}
            </p>
          </td>
        </tr>
        <tr>
          <td style="width: 19%;">Eng Dept Head (C/E)</td>
          <td style="width: 40%;">
            <p class="p-0 m-0" v-for="eng_dept_head in eng_dept_head[type]">{{ eng_dept_head.last_name }}, {{ eng_dept_head.first_name }}
              {{ eng_dept_head.middle_name }}
            </p>
          </td>
          <td style="width: 15%;"></td>
          <td></td>
        </tr>
      </table>
    </div>
    </template>

    <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-if="isAllowed('vessel-ia.appointment.ia-report.menu')">
            <router-link class="nav-link" :to="{
              name: 'internalAuditReport'
            }">
              <span>Internal Audit Report</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAllowed('vessel-ia.appointment.non-conformity.menu')">
            <router-link
              class="nav-link white" :to="constructRouterTo('NonConformity')"
            >
              <span>Non-Conformity/Observation</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAllowed('vessel-ia.appointment.corrective-action-report.menu')">
            <router-link
              class="nav-link white" :to="{name: 'IaCorrectiveActionReport'}"
            >
              <span>Corrective Action Report</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAllowed('vessel-ia.appointment.ship-particular.menu')">
            <router-link
              class="nav-link white" :to="{name: 'IAShipParticular' }"
            >
              <span>Ship's Particular</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
    <router-view
      :key="'nav-bar' + '_' + $route.params.vessel_id + '_' + $route.params.id + '_' + type"
    ></router-view>

    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="viewAuditorsList" tabindex="-2" :aria-labelledby="'viewAuditorsList' + 'Label'" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <form class="modal-content" @submit.prevent="">
          <div class="modal-header">
            <div class="modal-title w-100 text-center">
              <h5 class="font-weight-bold">{{ auditorTypeToView === 'leaders' ? 'Lead' : 'Member' }} Auditors List</h5>
            </div>
          </div>
          <div class="modal-body">
            <template v-if = "auditorTypeToView === 'leaders'">
              <span class="badge badge-pill badge-secondary mr-1 p-2" v-for="leader in auditRecord[type]?.leaders">
              {{ leader.name }}
            </span>
            </template>
            <template v-else>
            <span class="badge badge-pill badge-secondary mr-1 p-2" v-for="member in auditRecord[type]?.members">
              {{ member.name }}
            </span>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-primary" @click="closeViewAuditorList">OK</button>
          </div>
        </form>
      </div>
    </div>
    <PopperModal popper-id="requirementsBeforeClosing">
      <template #popperHeader>Required Fields Before Close ({{ type.toUpperCase() }})</template>
      <template #popperBody>
        <table style="width: 100%;">
          <tr>
            <th colspan="3" class="h6">INTERNAL AUDIT</th>
          </tr>
          <tr>
            <td style="width: 25%">
              Audit Type 2:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedAuditRecord[type.toLowerCase()]?.audit_type_2.length > 0 ? 'check' : 'times'"
                :class="selectedAuditRecord[type.toLowerCase()]?.audit_type_2.length > 0 ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedAuditRecord[type.toLowerCase()]?.audit_type_2.length > 0 ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ convertAuditType2IntoType(selectedAuditRecord[type]?.audit_type_2 ?? []).length === 0 ? '' : convertAuditType2IntoType(selectedAuditRecord[type.toLowerCase()]?.audit_type_2)}}
            </td>
          </tr>
          <tr v-if="selectedAuditRecord[type.toLowerCase()]?.audit_type_2.length === 1 && selectedAuditRecord[type.toLowerCase()]?.audit_type_2[0] === 2">
            <td style="width: 25%">
              Reason of this audit:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedAuditRecord[type.toLowerCase()]?.reason_of_audit !== null ? 'check' : 'times'"
                :class="selectedAuditRecord[type.toLowerCase()]?.reason_of_audit !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedAuditRecord[type.toLowerCase()]?.reason_of_audit !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="selectedAuditRecord[type.toLowerCase()]?.reason_of_audit !== null" class="e-text-blue-gray-light-5">
                . . . See Details on Field "Reason of this Audit" . . .
              </span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Audit Type 3:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedAuditRecord[type.toLowerCase()]?.audit_type_3.length > 0 ? 'check' : 'times'"
                :class="selectedAuditRecord[type.toLowerCase()]?.audit_type_3.length > 0 ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedAuditRecord[type.toLowerCase()]?.audit_type_3.length > 0 ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ convertAuditType3IntoType(selectedAuditRecord[type]?.audit_type_3 ?? []).length === 0 ? '' : convertAuditType3IntoType(selectedAuditRecord[type.toLowerCase()]?.audit_type_3)}}
            </td>
          </tr>
          <tr v-if="selectedAuditRecord[type.toLowerCase()]?.audit_type_3.length === 1 && selectedAuditRecord[type.toLowerCase()]?.audit_type_3[0] === 2 && selectedAuditRecord[type.toLowerCase()]?.received_approval === 1">
            <td style="width: 25%">
              Evidence of Approval from Flag State:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedAuditRecord[type.toLowerCase()]?.evidence_of_approval !== null ? 'check' : 'times'"
                :class="selectedAuditRecord[type.toLowerCase()]?.evidence_of_approval !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedAuditRecord[type.toLowerCase()]?.evidence_of_approval !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="selectedAuditRecord[type.toLowerCase()]?.evidence_of_approval !== null" class="e-text-blue-gray-light-5">
                . . . See Details on Field "Evidence of Approval from Flag State" . . .
              </span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Date of Audit:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedAuditRecord[type.toLowerCase()]?.audit_date_start.human !== null && selectedAuditRecord[type.toLowerCase()]?.audit_date_end.human !== null ? 'check' : 'times'"
                :class="selectedAuditRecord[type.toLowerCase()]?.audit_date_start.human !== null && selectedAuditRecord[type.toLowerCase()]?.audit_date_end.human !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedAuditRecord[type.toLowerCase()]?.audit_date_start.human !== null && selectedAuditRecord[type.toLowerCase()]?.audit_date_end.human !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ selectedAuditRecord[type.toLowerCase()]?.audit_date_start.human === selectedAuditRecord[type.toLowerCase()]?.audit_date_end.human ? selectedAuditRecord[type.toLowerCase()]?.audit_date_start.human : selectedAuditRecord[type.toLowerCase()]?.audit_date_start.human + ' - ' + selectedAuditRecord[type.toLowerCase()]?.audit_date_end.human }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Appointment Date:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedAuditRecord[type.toLowerCase()]?.appointment_date.human !== null ? 'check' : 'times'"
                :class="selectedAuditRecord[type.toLowerCase()]?.appointment_date.human !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedAuditRecord[type.toLowerCase()]?.appointment_date.human !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ selectedAuditRecord[type.toLowerCase()]?.appointment_date.human }}
            </td>
          </tr>
          <tr v-if="selectedAuditRecord[type.toLowerCase()]?.is_extended !== 0">
            <td style="width: 25%">
              Extension Date:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedAuditRecord[type.toLowerCase()]?.extended_date.human !== null ? 'check' : 'times'"
                :class="selectedAuditRecord[type.toLowerCase()]?.extended_date.human !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedAuditRecord[type.toLowerCase()]?.extended_date.human !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ selectedAuditRecord[type.toLowerCase()]?.extended_date.human }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Country:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="selectedAuditRecord[type.toLowerCase()]?.country_id !== null ? 'check' : 'times'"
                :class="selectedAuditRecord[type.toLowerCase()]?.country_id !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="selectedAuditRecord[type.toLowerCase()]?.country_id !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ filterDataById(countryKeys, selectedAuditRecord[type.toLowerCase()]?.country_id).length > 0 ? filterDataById(countryKeys, selectedAuditRecord[type.toLowerCase()]?.country_id)[0].name : '' }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Lead Auditor:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="filterAuditorsByPosition(selectedAuditRecord[type.toLowerCase()]?.auditors ?? []).leaders.length !== 0 ? 'check' : 'times'"
                :class="filterAuditorsByPosition(selectedAuditRecord[type.toLowerCase()]?.auditors ?? []).leaders.length !== 0 ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="filterAuditorsByPosition(selectedAuditRecord[type.toLowerCase()]?.auditors ?? []).leaders.length !== 0 ? 'e-bg-white' : 'e-bg-red-light-7'">
              <template  v-for="(leader, index) in filterAuditorsByPosition(selectedAuditRecord[type.toLowerCase()]?.auditors ?? []).leaders">
                <div v-if="index < 8" class="badge badge-pill badge-secondary mr-1 p-2 cursor-pointer mb-1">{{ leader.name }}</div>
              </template>
            </td>
          </tr>
        </table>
        <br>
        <table style="width: 100%;">
          <tr>
            <th colspan="3" class="h6">INTERNAL AUDIT REPORT</th>
          </tr>
          <tr>
            <th colspan="3" style="font-size: 13px;">Audit Results</th>
          </tr>
          <tr>
            <td style="width: 25%">
              Filled-up Attendance:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.attendance_url !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.attendance_url !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.attendance_url !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="internal_audit_reports[$route.params.type]?.attendance_url !== null" class="e-text-blue-gray-light-5">
                . . . See Details on "Internal Audit Report" Tab . . .
              </span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Checklist For Internal Audit:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.checklist_url !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.checklist_url !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.checklist_url !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="internal_audit_reports[$route.params.type]?.checklist_url !== null" class="e-text-blue-gray-light-5">
                . . . See Details on "Internal Audit Report" Tab . . .
              </span>
            </td>
          </tr>
        </table>
        <br>
        <table style="width: 100%;">
          <tr>
            <th colspan="3" style="font-size: 13px;">Time Limit for Corrective Action Implementation</th>
          </tr>
          <tr>
            <td style="width: 25%">
              To be done not later than:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_done_later_than['date-picker'] !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_done_later_than['date-picker'] !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_done_later_than['date-picker'] !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ internal_audit_reports[$route.params.type]?.ca_done_later_than.human }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Date:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_time_limit_date['date-picker'] !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_time_limit_date['date-picker'] !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_time_limit_date['date-picker'] !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ internal_audit_reports[$route.params.type]?.ca_time_limit_date.human }}
            </td>
          </tr>
          <tr v-if="$route.params.type === 'sms'">
            <td style="width: 25%">
              Director of Ship management office:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_ship_management_director_id !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_ship_management_director_id !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_ship_management_director_id !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ CaDirectorOrSecurity }}
            </td>
          </tr>
          <tr v-if="$route.params.type === 'isps'">
            <td style="width: 25%">
              Company Security officer:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_company_officer_id !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_company_officer_id !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_company_officer_id !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ CaDirectorOrSecurity }}
            </td>
          </tr>
          <tr v-if="$route.params.type === 'cyber'">
            <td style="width: 25%">
              Company Cyber Security officer:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_company_cyber_officer_id !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_company_cyber_officer_id !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_company_cyber_officer_id !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ CaDirectorOrSecurity }}
            </td>
          </tr>
        </table>
        <br>
        <table style="width: 100%;">
          <tr>
            <th colspan="3" style="font-size: 13px;">Corrective Action Implemented and reported by Master</th>
          </tr>
          <tr>
            <td style="width: 25%">
              Date:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_implemented_date['date-picker'] !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_implemented_date['date-picker'] !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_implemented_date['date-picker'] !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ internal_audit_reports[$route.params.type]?.ca_implemented_date.human }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Master:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_implemented_master !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_implemented_master !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_implemented_master !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ internal_audit_reports[$route.params.type]?.ca_implemented_master }}
            </td>
          </tr>
        </table>
        <br>
        <table style="width: 100%;">
          <tr>
            <th colspan="3" style="font-size: 13px;">Confirmation For Corrective Action</th>
          </tr>
          <tr>
            <td style="width: 25%">
              Date:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_confirmation_date['date-picker'] !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_confirmation_date['date-picker'] !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_confirmation_date['date-picker'] !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ internal_audit_reports[$route.params.type]?.ca_confirmation_date.human }}
            </td>
          </tr>
          <tr>
            <td style="width: 25%">
              Audit Team Leader:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.ca_confirmation_audit_leader !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.ca_confirmation_audit_leader !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.ca_confirmation_audit_leader !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ audit_team_leaders.filter(lead => lead.id === internal_audit_reports[$route.params.type]?.ca_confirmation_audit_leader)[0]?.name }}
            </td>
          </tr>
        </table>
        <br>
        <table style="width: 100%;">
          <tr>
            <th colspan="3" style="font-size: 13px;">Approval of Audit Completion</th>
          </tr>
          <tr>
            <td style="width: 25%">
              Date:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.audit_approval_date['date-picker'] !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.audit_approval_date['date-picker'] !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.audit_approval_date['date-picker'] !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ internal_audit_reports[$route.params.type]?.audit_approval_date.human }}
            </td>
          </tr>
          <tr v-if="$route.params.type === 'sms'">
            <td style="width: 25%">
              Director of Ship management office:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.approval_ship_management_director_id !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.approval_ship_management_director_id !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.approval_ship_management_director_id !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ approvalDirectoryOrSecurity }}
            </td>
          </tr>
          <tr v-if="$route.params.type === 'isps'">
            <td style="width: 25%">
              Company Security officer:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.approval_company_officer_id !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.approval_company_officer_id !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.approval_company_officer_id !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ approvalDirectoryOrSecurity }}
            </td>
          </tr>
          <tr v-if="$route.params.type === 'cyber'">
            <td style="width: 25%">
              Company Cyber Security officer:
            </td>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="internal_audit_reports[$route.params.type]?.approval_company_cyber_officer_id !== null ? 'check' : 'times'"
                :class="internal_audit_reports[$route.params.type]?.approval_company_cyber_officer_id !== null ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="internal_audit_reports[$route.params.type]?.approval_company_cyber_officer_id !== null ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ approvalDirectoryOrSecurity }}
            </td>
          </tr>
        </table>
      </template>
      <template #popperFooter>
        <small>NOTE: Details/Remarks are too long to display so we just put where it could be found.</small>
      </template>
    </PopperModal>
  </div>
</template>

<script>
import {AlertService} from "@/services/AlertService";
import AppLoading from "@/components/elements/AppLoading.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import AuditRecordMixin from "@/mixins/AuditRecordMixin";
import AuditorMixin from "@/mixins/AuditorMixin";
import {DataService} from "@/services/DataService";
import MultiSelectWithSearch from "@/components/MultiSelectWithSearch.vue";
import PopperModal from "@/components/elements/PopperModal.vue";
import SelectWithSearch from "@/components/common/SelectWithSearch.vue";
import {mapActions, mapGetters} from "vuex";
import requestLoaderMixin from "@/mixins/requestLoaderMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import _ from 'lodash';
import {vueAppMixin} from "@/mixins/vueAppMixin";

export default {
  name: 'VesselAuditMain',
  components: {SelectWithSearch, PopperModal, MultiSelectWithSearch, AppLoading, DatePicker},
  mixins : [AuditRecordMixin, AuditorMixin, requestLoaderMixin, permissionMixin, vueAppMixin],
  data(){
    return {
      isEditing: false,
      extension: 'no',
      dateOfAudit: [new Date('31 OCTOBER 2023'), '2023-10-31'],
      appointmentDate: '2023-10-30',
      auditRecord : {
        sms : null,
        isps : null,
        cyber : null
      },
      master : {
        sms : null,
        isps : null,
        cyber : null
      },
      deck_dept_head : {
        sms : null,
        isps : null,
        cyber : null
      },
      eng_dept_head : {
        sms : null,
        isps : null,
        cyber : null
      },
      auditorTypeToView : 'leaders',
      editTypeStatus : false,
      swalOptions: {
        'title': 'Sweet Alert Title',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      },
      auditTypeSettingColor : ''
    }
  },
  methods: {
    ...mapActions([
      'downloadAuditDocument'
    ]),
    viewReasonToAudit() {
      window.open(this.auditRecord[this.type].reason_of_audit.file_path,'_blank');
    },
    viewEvidenceOfApproval() {
      window.open(this.auditRecord[this.type].evidence_of_approval.file_path,'_blank');
    },
    resetChecklistData() {
      this.auditRecord[this.type].reason_of_audit = {
        file : null,
        file_path : null,
        request_delete : null,
      }
    },
    deleteReasonToAudit() {
      if (this.selectedAuditRecord[this.type].reason_of_audit !== null) {
        this.auditRecord[this.type].reason_of_audit.request_delete = true;
      }
      this.auditRecord[this.type].reason_of_audit.file = null;
      this.auditRecord[this.type].reason_of_audit.file_path = null;
    },
    deleteEvidenceOfApproval() {
      if (this.selectedAuditRecord[this.type].evidence_of_approval !== null) {
        this.auditRecord[this.type].evidence_of_approval.request_delete = true;
      }
      this.auditRecord[this.type].evidence_of_approval.file = null;
      this.auditRecord[this.type].evidence_of_approval.file_path = null;
    },

    addAuditAttachment(fileType) {
      if (fileType === 'reason_to_audit') {
        this.auditRecord[this.type].reason_of_audit.file = this.$refs.reason_to_audit.files[0];
        this.$refs.reason_to_audit.value = null;
      }

      if (fileType === 'evidence_of_approval') {
        this.auditRecord[this.type].evidence_of_approval.file = this.$refs.evidence_of_approval.files[0];
        this.$refs.evidence_of_approval.value = null;
      }
    },
    editTypeStatusValue() {
      this.editTypeStatus = true;
      this.setReportTypeStatusKeys(this.auditRecord);
    },
    getActiveTypesToDisable() {
      let types = [];
      for (let counter = 0; counter < this.audit_main_types.length; counter++) {
        const type = this.audit_main_types[counter].type;
        if (this.audit_main_types[counter].enabled === false && this.auditRecord[type] !== null) {
          types.push(type);
        }
      }
      return types;
    },
    getActiveTypeCurrentStatus() {
      let currentStatus = [];
      for (let counter = 0; counter < this.audit_main_types.length; counter++) {
        const type = this.audit_main_types[counter].type;
        if (this.auditRecord[type] !== null) {
          currentStatus.push({
            type : type,
            status : 'active'
          });
        } else {
          currentStatus.push({
            type : type,
            status : 'inactive'
          });
        }
      }
      return currentStatus;
    },
    constructDisableMessage(activeTypesToDisable) {
      activeTypesToDisable = activeTypesToDisable.map(type => type.toUpperCase());
      let supportingVerb = 'is';
      let supportingNoun = 'it';
      if (activeTypesToDisable.length > 1) {
        supportingVerb = 'are';
        supportingNoun = 'those';
      }
      const subjects = activeTypesToDisable.join(' and ');
      return subjects + ' ' + supportingVerb + ' currently enabled. Are you sure you want to disable ' + supportingNoun + '?';
    },
    async doUpdateSetting(hasDisabledType) {
      const activeTypes = this.getActiveTypes();
      const audit_settings = {
        audit_record_id : this.$route.params.id,
        settings        : activeTypes,
      }

      const updateAuditSettingsResponse = await this.updateAuditSettings(audit_settings);

      if (updateAuditSettingsResponse.result === false) {
        return AlertService.errorAlert(updateAuditSettingsResponse.message, 'UPDATING OF AUDIT RECORD SETTINGS');
      }


      let fakeAuditRecord = {};
      fakeAuditRecord.sms = activeTypes.includes('sms') === true ? true : null;
      fakeAuditRecord.isps = activeTypes.includes('isps') === true ? true : null;
      fakeAuditRecord.cyber = activeTypes.includes('cyber') === true ? true : null;

      let newLandingType = this.$route.params.type;
      if (hasDisabledType === true) {
        newLandingType = this.getFirstLandingType(fakeAuditRecord);
      }
      await this.getVesselAuditRecordById(this.$route.params.id);
      if (this.$route.params.type !== newLandingType) {
        await this.$router.replace({name: this.$route.name, params: {
            type: newLandingType
          }});
      }

      await this.initialize();
      this.hideLoaderRequest();
      AlertService.successAlert('Updated successfully', 'UPDATING OF AUDIT RECORD SETTINGS');

    },
    redirectToAuditRecordList() {
      return this.$router.push({name: 'VesselAuditRecordList',
        params: {
          id: this.$route.params.vessel_id
        },});
    },
    mapValueByKey(key) {
      const dataKeys = {
        audit_type_2 : 'Audit Type 2',
        audit_type_3 : 'Audit Type 3',
        appointment_date : 'Appointment Date',
        extension : 'Extension',
        extended_date : 'Extension Date',
        audit_country_id : 'Country',
        audit_port_id : 'Port',
        lead_ids : 'Lead Auditor',
        members_ids : 'Auditors',
      }
      return dataKeys[key];
    },
    async saveAuditTypeSetting() {
      if (this.disabledAuditSetting === true) {
        return;
      }

      const currentlyActiveTypes = this.getActiveTypeCurrentStatus();

      const activeTypes = _.filter(currentlyActiveTypes, { status: 'active' });
      const inactiveTypes = _.filter(currentlyActiveTypes, { status: 'inactive' });

      // validation occurs only if current active types count is 2 and inactive types count is 1
      if (activeTypes.length === 2 && inactiveTypes.length === 1) {
        const inactiveType = inactiveTypes[0].type;
        const type = this.audit_main_types.filter(data => data.type === inactiveType);
        if (type[0].enabled === true) {
          const checkTwoTypeIfTheSameResult = this.checkTwoActiveTypesIfTheSame(activeTypes);
          const same = checkTwoTypeIfTheSameResult.same;
          if (same === false) {
            const dataChanged = checkTwoTypeIfTheSameResult.data_keys_changed;
            let list = `<ul style="text-align: left; list-style-type: disc; padding-left: 20px;">`;
            dataChanged.forEach(item => {
              list += `<li>${this.mapValueByKey(item)}</li>`;
            });
            list += `</ul>`;

            this.audit_main_types.filter(item => item.type === inactiveTypes[0].type)[0].enabled = false;

            return await swal.fire({
              title: 'Enabling ' + inactiveTypes.map(item => item.type.toUpperCase()) + ' Failed. ' + activeTypes.map(item => item.type.toUpperCase()).join(' and ') + ' have different data for:',
              customClass: {
                confirmButton: 'btn btn-danger font-weight-bolder mr-2'
              },
              html: list,
              confirmButtonText: 'Ok',
              timer: 7000,
              buttonsStyling: false,
            });
          }
        }
      }
      const activeTypesToDisable = this.getActiveTypesToDisable();
      const disableMessage = this.constructDisableMessage(activeTypesToDisable);
      if (activeTypesToDisable.length > 0) {
        if(await AlertService.confirmUpdateAlert(disableMessage, 'Yes, disable it!')){
          await this.showLoaderRequest('UPDATING SETTING, PLEASE WAIT...');
          await this.doUpdateSetting(true);
          await this.hideLoaderRequest();
          await this.getDataFromCms();
        }
      } else {
        await this.showLoaderRequest('UPDATING SETTING, PLEASE WAIT...');
        await this.doUpdateSetting(false);
        await this.hideLoaderRequest();
        await this.getDataFromCms();
      }
    },
    checkTwoActiveTypesIfTheSame(activeTypes) {
      const types = activeTypes.map(data => data.type);

      const typeDataOne = {
        audit_type_2 : this.selectedAuditRecord[types[0]]?.audit_type_2,
        audit_type_3 : this.selectedAuditRecord[types[0]]?.audit_type_3,
        audit_date : [
          this.selectedAuditRecord[types[0]]?.audit_date_start['date-picker'],
          this.selectedAuditRecord[types[0]]?.audit_date_end['date-picker'],
        ],
        appointment_date : this.selectedAuditRecord[types[0]]?.appointment_date['date-picker'],
        extension : parseInt(this.selectedAuditRecord[types[0]]?.is_extended),
        extended_date : this.selectedAuditRecord[types[0]]?.extended_date['date-picker'],
        audit_country_id : this.selectedAuditRecord[types[0]]?.country_id,
        audit_port_id : this.selectedAuditRecord[types[0]]?.port_id,
        lead_ids: this.filterAuditorsByPosition(this.selectedAuditRecord[types[0]]?.auditors).leaders.map(leader => leader.id),
        members_ids: this.filterAuditorsByPosition(this.selectedAuditRecord[types[0]]?.auditors).members.map(member => member.id),
      }

      const typeDataTwo = {
        audit_type_2 : this.selectedAuditRecord[types[1]]?.audit_type_2,
        audit_type_3 : this.selectedAuditRecord[types[1]]?.audit_type_3,
        audit_date : [
          this.selectedAuditRecord[types[1]]?.audit_date_start['date-picker'],
          this.selectedAuditRecord[types[1]]?.audit_date_end['date-picker'],
        ],
        appointment_date : this.selectedAuditRecord[types[1]]?.appointment_date['date-picker'],
        extension : parseInt(this.selectedAuditRecord[types[1]]?.is_extended),
        extended_date : this.selectedAuditRecord[types[1]]?.extended_date['date-picker'],
        audit_country_id : this.selectedAuditRecord[types[1]]?.country_id,
        audit_port_id : this.selectedAuditRecord[types[1]]?.port_id,
        lead_ids: this.filterAuditorsByPosition(this.selectedAuditRecord[types[1]]?.auditors).leaders.map(leader => leader.id),
        members_ids: this.filterAuditorsByPosition(this.selectedAuditRecord[types[1]]?.auditors).members.map(member => member.id),
      }
      return {
        same              : DataService.checkIfChanged(typeDataOne, typeDataTwo) === false,
        data_keys_changed : DataService.getDataKeysChanges()
      };
    },
    async downloadDocument(documentType, disabled) {
      if (disabled === true) {
        return;
      }
      this.showLoaderRequest('DOWNLOADING FILE \nPLEASE WAIT...');

      let downloadResponse = await this.downloadAuditDocument({
        audit_id : this.selectedAuditRecord[this.type].id,
        audit_type : this.type,
        file : documentType
      });
      if (downloadResponse.result === false) {
        return AlertService.errorAlert(downloadResponse.message, 'DOWNLOADING OF ' + documentType.toUpperCase().replace('_', ' ') + ' FOR ' + this.type.toUpperCase());
      }
      this.hideLoaderRequest();
      const url = window.URL.createObjectURL(new Blob([downloadResponse.data],{type:'application/pdf'}))
      const filename = documentType.toUpperCase() + `_${Date.now()}.pdf`
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      AlertService.successAlert('Downloaded successfully', 'DOWNLOADING OF ' + documentType.toUpperCase().replace('_', ' ') + ' FOR ' + this.type.toUpperCase());
    },
    constructRouterTo(tabName) {
      if (tabName === 'NonConformity') {
        const ncrChildrenRoutes = ['NcrMainDetails', 'ObjectiveEvidenceDetail', 'OEMainDetails', 'OEAttachments'];

        return {
          name: ncrChildrenRoutes.includes(this.$route.name) === true ? this.$route.name : tabName,
          params : this.constructParams(tabName)
        }
      }
    },
    constructParams(tabName) {
      if (tabName === 'NonConformity') {
        if (this.$route.params.ncr_id === undefined) {
          return {
            ncr_id : 'index',
            ncr_type : this.$route.params.ncr_type !== undefined ? this.$route.params.ncr_type : 'none'
          }
        }
        return this.$route.params;
      }
    },
    startEditing() {
      this.isEditing = true
    },
    async doUpdate(typeStatusOnly = false) {
      const auditDateHasChanges = this.auditDateHasChanges;
      let auditRecord = {
        id : this.$route.params.id
      };
      auditRecord[this.type] = Object.assign({}, this.auditRecord[this.type]);
      auditRecord[this.type] = this.removeUnnecessaryKey(auditRecord[this.type], [
        'appointment_date_human',
        'audit_date_human',
        'leaders',
        'members',
      ]);

      if (auditRecord[this.type].reason_of_audit.request_delete === true) {
        await this.deleteAuditFile({
          id : this.$route.params.id,
          type : this.$route.params.type,
          audit_file_type : 'reason_of_audit'
        });
      }

      if (auditRecord[this.type].evidence_of_approval.request_delete === true) {
        await this.deleteAuditFile({
          id : this.$route.params.id,
          type : this.$route.params.type,
          audit_file_type : 'evidence_of_approval'
        });
      }

      if (auditRecord[this.type].reason_of_audit.file !== null) {
        auditRecord[this.type].reason_of_audit = auditRecord[this.type].reason_of_audit.file;
      } else {
        delete auditRecord[this.type].reason_of_audit;
      }

      if (auditRecord[this.type].evidence_of_approval.file !== null) {
        auditRecord[this.type].evidence_of_approval = auditRecord[this.type].evidence_of_approval.file;
      } else {
        delete auditRecord[this.type].evidence_of_approval;
      }

      let paramForm = {
        id : auditRecord.id,
        form_data : this.createFormDataParam(auditRecord, true)
      };

      await this.showLoaderRequest('UPDATING DATA, PLEASE WAIT...');
      let updateResponse = await this.updateAuditRecord(paramForm);

      if (updateResponse.result === false) {
        await this.hideLoaderRequest();
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF AUDIT RECORD FOR ' + this.type.toUpperCase())
      }
      await this.getVesselAuditRecordById(this.$route.params.id);
      this.setAuditReport();


      if (typeStatusOnly === false) {
        await this.hideLoaderRequest();
        this.isEditing = false;
        await AlertService.successAlert('Updated successfully', 'UPDATING OF AUDIT RECORD FOR ' + this.type.toUpperCase());


        if (auditDateHasChanges === true) {
          await this.delay(1000);
          swal.fire({...this.swalOptions, ...{title: 'CHANGES HAS BEEN MADE IN AUDIT DUE, ESTABLISHING CONNECTION TO CMS'}});
          swal.showLoading();
          await this.requestAndSetDataFromCms(this.type);
          swal.close();
        }
      } else {
        await this.hideLoaderRequest();
        this.editTypeStatus = false;
        await AlertService.successAlert('Updated successfully', 'UPDATING OF STATUS FOR ' + this.type.toUpperCase())
        await this.setReportTypeStatusKeys(this.auditRecord);
      }

    },
    async saveChanges() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        await this.doUpdate();
      }
    },
    async cancel() {
      if (this.hasChanges === false) {
        this.isEditing = false;
        this.setAuditReport();
        return;
      }
      if (await AlertService.cancelAlert()) {
        this.isEditing = false;
        this.setAuditReport();
      }
    },

    async setTypeTo(arg, disabled) {
      if (this.$route.params.type === arg) {
        return;
      }
      if (disabled === false) {
        const hasNonconformityPath = this.$route.path.split('/').includes('non-conformity');
        let routeName = this.$route.name;
        let params = {
          type: arg
        };
        if (hasNonconformityPath === true) {
          routeName = 'NonConformity';
          params.ncr_id = 'index';
        }

        if (this.hasChanges === true) {
          if (await AlertService.cancelChanges()) {
            await this.$router.replace({name: routeName, params: params});
            this.type = arg
            await this.setAuditReport();
            this.editTypeStatus = false;
            this.isEditing = false;
          }
        } else {
          await this.$router.replace({name: routeName, params: params});
          this.type = arg;
          this.editTypeStatus = false;
          this.isEditing = false;
        }
      }
    },
    setAuditReport() {
      if (this.selectedAuditRecord.sms !== null) {
        this.auditRecord.sms = this.setTypeValues(this.selectedAuditRecord.sms);
        this.audit_main_types[0].enabled = true;
      } else {
        this.auditRecord.sms = null;
      }

      if (this.selectedAuditRecord.isps !== null) {
        this.auditRecord.isps = this.setTypeValues(this.selectedAuditRecord.isps);
        this.audit_main_types[1].enabled = true;
      } else {
        this.auditRecord.isps = null;
      }

      if (this.selectedAuditRecord.cyber !== null) {
        this.auditRecord.cyber = this.setTypeValues(this.selectedAuditRecord.cyber);
        this.audit_main_types[2].enabled = true;
      } else {
        this.auditRecord.cyber = null;
      }
    },
    setInitialActiveType() {
      this.type = this.$route.params.type;
    },
    viewAuditorsList(auditorType) {
      this.auditorTypeToView = auditorType;
      $('#viewAuditorsList').modal('show');
    },
    closeViewAuditorList() {
      $('#viewAuditorsList').modal('hide');
    },
    setAuditTypeOne(indexType, type) {
      this.audit_main_types[indexType].enabled = !this.audit_main_types[indexType].enabled;
    },
    setAuditTypeTwo(type) {
      this.auditRecord[this.type].audit_type_2 = this.auditRecord[this.type].audit_type_2.filter(item => item === type);
      this.auditRecord[this.type] = DataService.pushOrRemoveInData(this.auditRecord[this.type], 'audit_type_2', type);
    },
    setAuditTypeThree(type) {
      this.auditRecord[this.type].audit_type_3 = this.auditRecord[this.type].audit_type_3.filter(item => item === type);
      this.auditRecord[this.type] = DataService.pushOrRemoveInData(this.auditRecord[this.type], 'audit_type_3', type);
    },
    setLeadMembers(leadMemberIds) {
      this.auditRecord[this.type].lead_ids = leadMemberIds.selected.length > 0 ? leadMemberIds.selected : [];
    },
    setNonLeadMembers(nonLeadMemberIds) {
      this.auditRecord[this.type].members_ids = nonLeadMemberIds.selected.length > 0 ? nonLeadMemberIds.selected : [];
    },
    cancelEditTypeStatus() {
      this.editTypeStatus = false;
      this.auditRecord[this.type].report_status_id = this.selectedAuditRecord[this.type]?.report_status_id;
    },
    async saveTypeStatus() {
      let currStatusName = this.convertReportStatus(this.selectedAuditRecord[this.type]?.report_status_id).name;
      let changeReportStatusName = this.convertReportStatus(this.auditRecord[this.type]?.report_status_id).name;
      const verify = swal.fire({
        icon: 'question',
        title: 'CHANGE REPORT STATUS',
        html: `CHANGING <strong> ${currStatusName} </strong> STATUS TO <strong> ${changeReportStatusName} </strong> STATUS `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'PROCEED',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545'
      }).then(result => {
        return result;
      })
      if ((await verify).isConfirmed) {
        await this.doUpdate(true);
      }
    },
    async requestAndSetDataFromCms(type) {
      const crewListParam = {
        onboard_from: this.auditRecord[type].audit_date[0],
        onboard_to: this.auditRecord[type].audit_date[1],
        vesselName: this.vessel.name,
      };

      const response = await this.getCrewKeys(crewListParam);

      if (response.hasOwnProperty('errors')) {
        let errors = response.errors
        let error_message = '';
        error_message += '<ol style="text-align: left; font-size: 1em">';
        Object.keys(errors).forEach(key => {
          let err_msg = errors[key].join(',');
          error_message += '<li class="e-text-red"><small>* ' + err_msg + '</small></li>';
        });
        error_message += '</ol>';
        AlertService.errorAlert(error_message, 'GET CREW ONBOARD LISTS')
      } else {
        this.master[type] = response.crews.filter((crew) => crew.rank_id === 1);
        this.eng_dept_head[type] = response.crews.filter((crew) => crew.rank_id === 2);
        this.deck_dept_head[type] = response.crews.filter((crew) => crew.rank_id === 3);
      }
    },
    async getDataFromCms() {
      const activeTypes = this.getActiveTypes();
      swal.fire({...this.swalOptions, ...{title: 'ESTABLISHING CONNECTION TO CMS'}});
      swal.showLoading();

      for (const type of activeTypes) {
        await this.requestAndSetDataFromCms(type);
      }
      swal.close();
    },
    async initialize() {
      this.setInitialActiveType();
      await this.setAuditReport();
      await this.addRequestKeys([
        'getReportStatusKeys'
      ]);
      await this.generateRequestKeys({});
      await this.setReportTypeStatusKeys(this.auditRecord);
    },
  },
  computed: {
    ports() {
      return this.portKeys.filter(port => port.country_id === this.auditRecord[this.type]?.audit_country_id)
    },
    auditDateHasChanges() {
      const originalData = {
        audit_date : [
          this.selectedAuditRecord[this.type]?.audit_date_start['date-picker'],
          this.selectedAuditRecord[this.type]?.audit_date_end['date-picker'],
        ],
      }

      const editedData = {
        audit_date : this.auditRecord[this.type].audit_date,
      }

      return DataService.checkIfChanged(originalData, editedData);
    },
    hasChanges() {
      let originalData = {
        audit_type_2 : this.selectedAuditRecord[this.type]?.audit_type_2,
        audit_type_3 : this.selectedAuditRecord[this.type]?.audit_type_3,
        received_approval : this.selectedAuditRecord[this.type]?.received_approval ?? 0,
        appointment_date : this.selectedAuditRecord[this.type]?.appointment_date['date-picker'],
        extension : parseInt(this.selectedAuditRecord[this.type]?.is_extended),
        extended_date : this.selectedAuditRecord[this.type]?.extended_date['date-picker'],
        audit_country_id : this.selectedAuditRecord[this.type]?.country_id,
        audit_port_id : this.selectedAuditRecord[this.type]?.port_id,
        lead_ids: this.filterAuditorsByPosition(this.selectedAuditRecord[this.type.toLowerCase()]?.auditors).leaders.map(leader => leader.id),
        members_ids: this.filterAuditorsByPosition(this.selectedAuditRecord[this.type.toLowerCase()]?.auditors).members.map(member => member.id),
        audit_master : this.selectedAuditRecord[this.type]?.audit_master,
        deck_dept_head : this.selectedAuditRecord[this.type]?.deck_dept_head,
        eng_dept_head : this.selectedAuditRecord[this.type]?.eng_dept_head
      }

      let editedData = {
        audit_type_2 : this.auditRecord[this.type].audit_type_2,
        audit_type_3 : this.auditRecord[this.type].audit_type_3,
        received_approval : this.auditRecord[this.type].received_approval,
        appointment_date : this.auditRecord[this.type].appointment_date,
        extension : parseInt(this.auditRecord[this.type].extension),
        extended_date : this.auditRecord[this.type].extension_date,
        audit_country_id : this.auditRecord[this.type].audit_country_id,
        audit_port_id : this.auditRecord[this.type].audit_port_id,
        lead_ids : this.auditRecord[this.type].lead_ids,
        members_ids : this.auditRecord[this.type].members_ids,
        audit_master : this.auditRecord[this.type].audit_master,
        deck_dept_head : this.auditRecord[this.type].deck_dept_head,
        eng_dept_head : this.auditRecord[this.type].eng_dept_head
      }

      const requestReasonToAuditDelete = this.auditRecord[this.type].reason_of_audit.request_delete === true;
      const requestEvidenceApprovalDelete = this.auditRecord[this.type].evidence_of_approval.request_delete === true;
      const hasNewReasonToAudit = this.auditRecord[this.type].reason_of_audit.file !== null;
      const hasNewApprovalEvidence = this.auditRecord[this.type].evidence_of_approval.file !== null;

      return requestEvidenceApprovalDelete ||
        hasNewApprovalEvidence ||
        hasNewReasonToAudit ||
        requestReasonToAuditDelete ||
        this.auditDateHasChanges ||
        DataService.checkIfChanged(originalData, editedData);
    },
    editableReportStatusButton() {
      const currentReportStatusId = this.selectedAuditRecord[this.type]?.report_status_id;
      const NEW = 1;
      const OPEN = 2;
      const CLOSED = 3;
      const READY_TO_CLOSE = 4;
      const RECORD_ONLY = 5;

      const isAuditType3Remote = this.selectedAuditRecord[this.type.toLowerCase()]?.audit_type_3.length === 1 && this.selectedAuditRecord[this.type.toLowerCase()]?.audit_type_3[0] === 2;
      if (isAuditType3Remote === true) {
        const haveReceivedApprovalIsNo = this.selectedAuditRecord[this.type.toLowerCase()]?.received_approval === 0;
        if (haveReceivedApprovalIsNo === true) {
          return false;
        }
      }

      if (this.user.is_vessel === true) {
        return false;
      } else {
        if (currentReportStatusId === NEW) {
          return this.isAllowed('vessel-ia.appointment.status.editable.new') || this.user.id === this.selectedAuditRecord.created_by_id;
        }

        if (currentReportStatusId === OPEN) {
          return this.isAllowed('vessel-ia.appointment.status.editable.open') || this.user.id === this.selectedAuditRecord.created_by_id;
        }

        if (currentReportStatusId === READY_TO_CLOSE) {
          return this.isAllowed('vessel-ia.appointment.status.editable.ready-to-close') || this.user.id === this.selectedAuditRecord.created_by_id;
        }

        if (currentReportStatusId === CLOSED) {
          return this.isAllowed('vessel-ia.appointment.status.editable.closed');
        }

        if (currentReportStatusId === RECORD_ONLY) {
          return this.isAllowed('vessel-ia.appointment.status.editable.record-only');
        }
      }

    }
  },
  async created() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    await this.initialize();
    await this.getDataFromCms();
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>

<style scoped lang="scss">
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}

table td {
  padding: 2px !important;
}

.gray {
  color: #435464
}

.active_custom {
  background-color: #d3313c;
  color: #fff;
}

.no-bg-change:active {
  background-color: #e9ecef !important;
}
.no-bg-change:focus, {
  background-color: transparent !important;
}

.transparent-audit-type {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
</style>
